:root {
  --color-white: #fff;
  --color-black: #000;
  --color-red: #ef4444;
}

.errorContainer {
  text-align: center;
  margin-top: 100px;
}

.link {
  text-decoration: none;
  color: var(--color-white);
  font-size: 17px;
}

.errorContainer .linkButton {
  width: 30%;
}

.errorTitle {
  color: var(--color-red);
}

.errorText {
  color: var(--color-red);
}