/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light-webfont.eot");
  src: url("../fonts/Roboto-Light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Light-webfont.woff") format("woff"), url("../fonts/Roboto-Light-webfont.ttf") format("truetype"), url("../fonts/Roboto-Light-webfont.svg#robotolight") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular-webfont.eot");
  src: url("../fonts/Roboto-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular-webfont.woff") format("woff"), url("../fonts/Roboto-Regular-webfont.ttf") format("truetype"), url("../fonts/Roboto-Regular-webfont.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium-webfont.eot");
  src: url("../fonts/Roboto-Medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium-webfont.woff") format("woff"), url("../fonts/Roboto-Medium-webfont.ttf") format("truetype"), url("../fonts/Roboto-Medium-webfont.svg#robotomedium") format("svg");
  font-weight: 600;
  font-style: normal;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  font-family: Roboto, sans-serif;
  font-weight: lighter;
}

a {
  text-decoration: underline;
  color: #4a90e2;
  font-size: 0.875rem;
  font-weight: lighter;
}
a.disabled {
  color: #4a4a4a;
  cursor: not-allowed;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: 1.5rem;
  color: #4a4a4a;
  font-weight: normal;
  text-align: center;
  margin-bottom: 10px;
}

p {
  margin-top: 20px;
  line-height: 1.35rem;
}
p.error {
  color: #cb6566;
}
p.success {
  color: #339999;
}
p:first-of-type {
  margin-top: 0;
}

label {
  font-size: 0.875rem;
  font-weight: normal;
}

b, .bold {
  font-weight: normal;
}

.hidden {
  display: none;
}

.notice {
  color: #cb6566;
  font-weight: lighter;
  font-size: 0.875rem;
  margin-bottom: 10px;
}

@font-face {
  font-family: "RwWidgets";
  font-weight: normal;
  font-style: normal;
  src: url("~react-widgets/lib/fonts/rw-widgets.eot?v=4.1.0");
  src: url("~react-widgets/lib/fonts/rw-widgets.eot?#iefix&v=4.1.0") format("embedded-opentype"), url("~react-widgets/lib/fonts/rw-widgets.woff?v=4.1.0") format("woff"), url("~react-widgets/lib/fonts/rw-widgets.ttf?v=4.1.0") format("truetype"), url("~react-widgets/lib/fonts/rw-widgets.svg?v=4.1.0#fontawesomeregular") format("svg");
}
.rw-btn,
.rw-input-reset,
.rw-input,
.rw-filter-input,
.rw-dropdown-list-autofill {
  color: inherit;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background: none;
  background-image: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.rw-btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}

select.rw-input, select.rw-filter-input, select.rw-dropdown-list-autofill {
  text-transform: none;
}

html input[type=button].rw-input, html input[type=button].rw-filter-input, html input[type=button].rw-dropdown-list-autofill {
  -webkit-appearance: button;
  cursor: pointer;
}

textarea.rw-input, textarea.rw-filter-input, textarea.rw-dropdown-list-autofill {
  overflow: auto;
  resize: vertical;
}

button[disabled].rw-input, button[disabled].rw-filter-input, button[disabled].rw-dropdown-list-autofill,
fieldset[disabled] .rw-input,
fieldset[disabled] .rw-filter-input,
fieldset[disabled] .rw-dropdown-list-autofill,
html input[disabled].rw-input,
html input[disabled].rw-filter-input,
html input[disabled].rw-dropdown-list-autofill {
  cursor: not-allowed;
}

button.rw-input::-moz-focus-inner, button.rw-filter-input::-moz-focus-inner, button.rw-dropdown-list-autofill::-moz-focus-inner,
input.rw-input::-moz-focus-inner,
input.rw-filter-input::-moz-focus-inner,
input.rw-dropdown-list-autofill::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

/* -------------- */
@font-face {
  font-family: "RwWidgets";
  font-weight: normal;
  font-style: normal;
  src: url("~react-widgets/lib/fonts/rw-widgets.eot?v=4.1.0");
  src: url("~react-widgets/lib/fonts/rw-widgets.eot?#iefix&v=4.1.0") format("embedded-opentype"), url("~react-widgets/lib/fonts/rw-widgets.woff?v=4.1.0") format("woff"), url("~react-widgets/lib/fonts/rw-widgets.ttf?v=4.1.0") format("truetype"), url("~react-widgets/lib/fonts/rw-widgets.svg?v=4.1.0#fontawesomeregular") format("svg");
}
.rw-i {
  display: inline-block;
  color: inherit;
  font-family: RwWidgets;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.rw-i-caret-down:before {
  content: "\e803";
}

.rw-i-caret-up:before {
  content: "\e800";
}

.rw-i-chevron-left:before {
  content: "\f104";
}

.rw-i-chevron-right:before {
  content: "\f105";
}

.rw-i-clock-o:before {
  content: "\e805";
}

.rw-i-calendar:before {
  content: "\e804";
}

.rw-i-search:before {
  content: "\e801";
}

.rw-btn {
  position: relative;
  color: #bcced1;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
}
.rw-state-readonly .rw-btn, .rw-state-disabled .rw-btn {
  cursor: not-allowed;
}

.rw-btn-select {
  opacity: 0.75;
  transition: opacity 150ms ease-in;
}
.rw-btn-select:hover, .rw-state-focus .rw-btn-select, :hover > .rw-btn-select {
  opacity: 1;
}

.rw-btn-primary {
  width: 100%;
  white-space: normal;
  line-height: 2em;
}
.rw-btn-primary:hover {
  background-color: #e6e6e6;
}

.rw-btn-select[disabled],
.rw-btn-primary[disabled],
fieldset[disabled] .rw-btn-select,
fieldset[disabled] .rw-btn-primary {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: none;
}

/* for debugging */
@keyframes react-widgets-autofill-start {
  from {
    /**/
  }
  to {
    /**/
  }
}
@keyframes react-widgets-autofill-cancel {
  from {
    /**/
  }
  to {
    /**/
  }
}
.rw-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.rw-widget {
  background-clip: border-box;
  border: none;
  color: #bcced1;
  font-size: 1em;
  font-family: inherit;
  outline: none;
  position: relative;
}
.rw-widget, .rw-widget * {
  box-sizing: border-box;
}
.rw-widget:before, .rw-widget *:before, .rw-widget:after, .rw-widget *:after {
  box-sizing: border-box;
}
.rw-widget > .rw-widget-container {
  width: 100%;
  margin: 0;
}

.rw-widget-container {
  background-color: #fff;
  border: #e5eced 1px solid;
  border-radius: 5px;
}
.rw-widget-container.rw-state-focus, .rw-widget-container.rw-state-focus:hover, .rw-state-focus > .rw-widget-container, .rw-state-focus > .rw-widget-container:hover {
  background-color: #fff;
  border-color: #e5eced;
  box-shadow: 0 11px 14px -10px #e5eced;
}
.rw-widget-container.rw-state-readonly, .rw-state-readonly > .rw-widget-container {
  cursor: not-allowed;
}
.rw-widget-container.rw-state-disabled, .rw-widget-container.rw-state-disabled:hover, .rw-widget-container.rw-state-disabled:active, .rw-state-disabled > .rw-widget-container, .rw-state-disabled > .rw-widget-container:hover, .rw-state-disabled > .rw-widget-container:active, fieldset[disabled] .rw-widget-container, fieldset[disabled] .rw-widget-container:hover, fieldset[disabled] .rw-widget-container:active {
  box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.rw-widget-picker {
  position: relative;
  overflow: hidden;
  border-collapse: separate;
  display: inline-table;
  height: 2rem;
}
.rw-widget-picker > * {
  position: relative;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  display: table-cell;
}
.rw-widget-picker > .rw-select {
  width: 1%;
  white-space: nowrap;
}
.rw-open > .rw-widget-picker {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.rw-open-up > .rw-widget-picker {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
fieldset[disabled] .rw-widget-picker, .rw-state-disabled > .rw-widget-picker {
  background-color: white;
}

.rw-multiselect > .rw-widget-picker {
  height: auto;
}

.rw-select {
  cursor: pointer;
}
.rw-select > * {
  width: 1.9em;
  height: 100%;
}
.rw-state-readonly .rw-select, .rw-state-disabled .rw-select {
  cursor: not-allowed;
}

.rw-select-bordered {
  cursor: pointer;
  border: none;
  border-left: #e5eced 1px solid;
}
.rw-select-bordered:hover, .rw-select-bordered:active {
  background-color: #e6e6e6;
}
.rw-select-bordered:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.rw-state-disabled .rw-select-bordered, .rw-state-disabled .rw-select-bordered:hover, .rw-state-disabled .rw-select-bordered:active, .rw-state-readonly .rw-select-bordered, .rw-state-readonly .rw-select-bordered:hover, .rw-state-readonly .rw-select-bordered:active, fieldset[disabled] .rw-select-bordered, fieldset[disabled] .rw-select-bordered:hover, fieldset[disabled] .rw-select-bordered:active {
  cursor: not-allowed;
  background-color: inherit;
  background-image: none;
  box-shadow: none;
}
.rw-rtl .rw-select-bordered {
  border-right: #e5eced 1px solid;
  border-left: none;
}

.rw-rtl {
  direction: rtl;
}

.rw-input-reset, .rw-input, .rw-filter-input, .rw-dropdown-list-autofill {
  outline: 0;
}
.rw-input-reset::-moz-placeholder, .rw-input::-moz-placeholder, .rw-filter-input::-moz-placeholder, .rw-dropdown-list-autofill::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.rw-input-reset:-ms-input-placeholder, .rw-input:-ms-input-placeholder, .rw-filter-input:-ms-input-placeholder, .rw-dropdown-list-autofill:-ms-input-placeholder {
  color: #999;
}
.rw-input-reset::-webkit-input-placeholder, .rw-input::-webkit-input-placeholder, .rw-filter-input::-webkit-input-placeholder, .rw-dropdown-list-autofill::-webkit-input-placeholder {
  color: #999;
}

.rw-input, .rw-filter-input, .rw-dropdown-list-autofill {
  color: #4a4a4a;
  padding: 0 0.857em;
  background-color: #fff;
}
.rw-input[type=text]::-ms-clear, [type=text].rw-filter-input::-ms-clear, [type=text].rw-dropdown-list-autofill::-ms-clear {
  display: none;
}
.rw-input[disabled], [disabled].rw-filter-input, [disabled].rw-dropdown-list-autofill, fieldset[disabled] .rw-input, fieldset[disabled] .rw-filter-input, fieldset[disabled] .rw-dropdown-list-autofill {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: white;
  border-color: #e5eced;
}
.rw-input[readonly], [readonly].rw-filter-input, [readonly].rw-dropdown-list-autofill {
  cursor: not-allowed;
}

.rw-i.rw-loading {
  display: block;
  background: url("~react-widgets/lib/img/loading.gif") no-repeat center;
  min-width: 16px;
  width: 1.9em;
  height: 16px;
}
.rw-i.rw-loading:before {
  content: "";
}

.rw-placeholder {
  color: #999;
}

.rw-detect-autofill:-webkit-autofill {
  animation-name: react-widgets-autofill-start;
  transition: background-color 50000s ease-in-out 0s;
}
.rw-detect-autofill:not(:-webkit-autofill) {
  animation-name: react-widgets-autofill-cancel;
}

.rw-webkit-autofill .rw-widget-container,
.rw-input:-webkit-autofill,
.rw-filter-input:-webkit-autofill,
.rw-dropdown-list-autofill:-webkit-autofill {
  background-color: rgb(250, 255, 189) !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

.rw-widget-input, .rw-filter-input {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.rw-widget-input.rw-state-focus, .rw-state-focus.rw-filter-input {
  box-shadow: 0 11px 14px -10px #e5eced, inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.rw-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1em;
  outline: 0;
  overflow: auto;
  max-height: 200px;
}

.rw-list-option {
  user-select: none;
  color: #4a4a4a;
  cursor: pointer;
  border: 1px solid transparent;
}
.rw-list-option.rw-state-focus, .rw-list-option.rw-state-focus:hover {
  background-color: transparent;
  border-color: #e5eced;
  color: #4a4a4a;
}
.rw-list-option:hover, .rw-list-option:hover.rw-state-focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4a4a4a;
}
.rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover {
  background-color: transparent;
  border-color: transparent;
  color: #4a4a4a;
}
fieldset[disabled] .rw-list-option, .rw-list-option.rw-state-disabled, .rw-list-option.rw-state-readonly {
  box-shadow: none;
  cursor: not-allowed;
  color: #b0b0b0;
  opacity: 0.7;
}
fieldset[disabled] .rw-list-option:hover, .rw-list-option.rw-state-disabled:hover, .rw-list-option.rw-state-readonly:hover {
  background: none;
  border-color: transparent;
}

.rw-list-empty,
.rw-list-option,
.rw-list-optgroup {
  padding: 15px 20px;
  outline: 0;
}

.rw-list-optgroup {
  font-weight: bold;
  padding-top: 7px;
}

.rw-list-option-create {
  border-top: 1px #e5eced solid;
}

.rw-dropdown-list-autofill {
  padding: 0;
}

.rw-dropdown-list-input {
  background-color: transparent;
  vertical-align: middle;
  padding-right: 0;
  /* ellipsis */
}
.rw-dropdown-list-input {
  max-width: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.rw-rtl .rw-dropdown-list-input {
  padding-right: 0.857em;
  padding-left: 0;
}

.rw-filter-input {
  position: relative;
  margin: 4px;
  padding-right: 0;
}
.rw-filter-input .rw-rtl {
  padding-right: 0.857em;
  padding-left: 0;
}
.rw-filter-input .rw-select,
.rw-filter-input .rw-btn {
  opacity: 0.75;
  cursor: text;
}
.rw-filter-input > .rw-select, .rw-filter-input > .rw-select:active, .rw-filter-input > .rw-select:hover {
  background: none;
  cursor: initial;
  box-shadow: none;
}

.rw-number-picker .rw-btn {
  cursor: pointer;
  height: calc(1rem - 1px);
  /** ie8 fallback **/
  margin-top: -1px\9 ;
  height: 1rem\9 ;
  /** --- **/
  line-height: 1rem;
  line-height: calc(1rem - 1px);
  display: block;
  border: none;
}
.rw-number-picker .rw-btn:hover, .rw-number-picker .rw-btn:active {
  background-color: #e6e6e6;
}
.rw-number-picker .rw-btn:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.rw-state-disabled .rw-number-picker .rw-btn, .rw-state-disabled .rw-number-picker .rw-btn:hover, .rw-state-disabled .rw-number-picker .rw-btn:active, .rw-state-readonly .rw-number-picker .rw-btn, .rw-state-readonly .rw-number-picker .rw-btn:hover, .rw-state-readonly .rw-number-picker .rw-btn:active, fieldset[disabled] .rw-number-picker .rw-btn, fieldset[disabled] .rw-number-picker .rw-btn:hover, fieldset[disabled] .rw-number-picker .rw-btn:active {
  cursor: not-allowed;
  background-color: inherit;
  background-image: none;
  box-shadow: none;
}
.rw-number-picker .rw-select {
  vertical-align: middle;
}
.rw-number-picker .rw-select, .rw-number-picker .rw-select:hover, .rw-number-picker .rw-select:active {
  box-shadow: none;
}

.rw-calendar-popup {
  right: auto;
  min-width: 0;
  width: 18em;
}

.rw-calendar {
  border-radius: 0;
  background-color: #fff;
  border: #e5eced 1px solid;
  overflow: hidden;
}
.rw-calendar.rw-popup {
  border-color: #e5eced;
}

.rw-calendar-now {
  font-weight: bold;
}

.rw-calendar-btn-left,
.rw-calendar-btn-right {
  width: 12.5%;
}

.rw-calendar-btn-view {
  width: 75%;
}

.rw-calendar-footer {
  border-top: 1px solid #e5eced;
}

.rw-calendar-grid {
  outline: none;
  height: 200/14em;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: #fff;
}

.rw-head-cell {
  text-align: center;
  border-bottom: 1px solid #e5eced;
  padding: 0.25em;
}

.rw-cell {
  color: #bcced1;
  border-radius: 5px;
  cursor: pointer;
  line-height: normal;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.25em;
}
.rw-cell:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #bcced1;
}
.rw-cell.rw-state-focus, .rw-cell.rw-state-focus:hover {
  background-color: transparent;
  border-color: #e5eced;
  color: #bcced1;
}
.rw-cell.rw-state-selected, .rw-cell.rw-state-selected:hover {
  background-color: transparent;
  border-color: transparent;
  color: white;
}
.rw-cell.rw-state-disabled {
  color: white;
  opacity: 0.7;
}
.rw-cell.rw-state-disabled:hover {
  background: none;
  border-color: transparent;
}
.rw-calendar-month .rw-cell {
  text-align: center;
}

.rw-cell-off-range {
  color: white;
}

.rw-calendar-transition-group {
  position: relative;
}

.rw-calendar-transition {
  transition: transform 300ms;
  overflow: hidden;
}

.rw-calendar-transition-top {
  transform: translateY(-100%);
}

.rw-calendar-transition-bottom {
  transform: translateY(100%);
}

.rw-calendar-transition-right {
  transform: translateX(-100%);
}

.rw-calendar-transition-left {
  transform: translateX(100%);
}

.rw-calendar-transition-entering.rw-calendar-transition-top, .rw-calendar-transition-entering.rw-calendar-transition-bottom,
.rw-calendar-transition-entered.rw-calendar-transition-top,
.rw-calendar-transition-entered.rw-calendar-transition-bottom {
  transform: translateY(0);
}
.rw-calendar-transition-entering.rw-calendar-transition-right, .rw-calendar-transition-entering.rw-calendar-transition-left,
.rw-calendar-transition-entered.rw-calendar-transition-right,
.rw-calendar-transition-entered.rw-calendar-transition-left {
  transform: translateX(0);
}

.rw-calendar-transition-exiting.rw-calendar-transition-top {
  transform: translateY(100%);
}
.rw-calendar-transition-exiting.rw-calendar-transition-bottom {
  transform: translateY(-100%);
}
.rw-calendar-transition-exiting.rw-calendar-transition-right {
  transform: translateX(100%);
}
.rw-calendar-transition-exiting.rw-calendar-transition-left {
  transform: translateX(-100%);
}

.rw-select-list {
  overflow: auto;
  position: relative;
}
.rw-select-list .rw-list {
  max-height: none;
  font-size: 1em;
}

.rw-select-list-label {
  display: block;
  position: relative;
  font-weight: normal;
  cursor: inherit;
  padding-left: 20px;
  margin: 0;
}
.rw-rtl .rw-select-list-label {
  padding-left: 0;
  padding-right: 20px;
}

input.rw-select-list-input {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  top: 0.1em\9 ;
  margin: 0;
  line-height: normal;
  cursor: inherit;
}
.rw-rtl input.rw-select-list-input {
  left: auto;
  right: 0;
}

.rw-loading-mask {
  content: "";
  background: url("~react-widgets/lib/img/loader-big.gif") no-repeat center;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  opacity: 0.7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.rw-multiselect {
  cursor: text;
}
.rw-multiselect .rw-input-reset, .rw-multiselect .rw-input, .rw-multiselect .rw-dropdown-list-autofill, .rw-multiselect .rw-filter-input {
  height: calc(2rem - 2px);
  /** ie8 fallback **/
  margin-top: -2px\9 ;
  height: 2rem\9 ;
  /** --- **/
  border-width: 0;
  width: auto;
  max-width: 100%;
  padding: 0 0.857em;
}
.rw-multiselect .rw-select {
  vertical-align: middle;
}
.rw-multiselect .rw-select, .rw-multiselect .rw-select:hover, .rw-multiselect .rw-select:active {
  box-shadow: none;
  background: none;
}

.rw-multiselect-taglist {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  vertical-align: 0;
  outline: none;
}

.rw-multiselect-tag {
  display: inline-table;
  color: inherit;
  padding: 0 0.35em 0 0.35em;
  margin-left: calc(0.23rem - 1px);
  margin-top: 0.23rem;
  margin-top: calc(0.23rem - 1px);
  height: 1.54rem;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ccc;
  cursor: default;
  vertical-align: top;
  text-align: center;
  overflow: hidden;
  max-width: 100%;
}
.rw-multiselect-tag > * {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}
.rw-rtl .rw-multiselect-tag {
  margin-left: 0;
  margin-right: calc(0.23rem - 1px);
  padding: 0 0.35em 0 0.35em;
}
.rw-multiselect-tag.rw-state-focus, .rw-multiselect-tag.rw-state-focus:hover {
  background-color: transparent;
  border-color: #e5eced;
  color: #bcced1;
}
.rw-multiselect-tag.rw-state-readonly, .rw-multiselect-tag.rw-state-disabled, .rw-state-readonly .rw-multiselect-tag, .rw-state-disabled .rw-multiselect-tag, fieldset[disabled] .rw-multiselect-tag {
  cursor: not-allowed;
}
.rw-multiselect-tag.rw-state-disabled, .rw-state-disabled .rw-multiselect-tag, fieldset[disabled] .rw-multiselect-tag {
  opacity: 0.65;
}
fieldset[disabled] .rw-multiselect-tag {
  box-shadow: none;
  cursor: not-allowed;
}

.rw-multiselect-tag-btn {
  color: inherit;
  margin-left: 0.25em;
}
.rw-rtl .rw-multiselect-tag-btn {
  margin-left: 0;
  margin-right: 0.25em;
}

.rw-autocomplete .rw-select {
  position: absolute;
  display: block;
  width: auto;
  top: 0;
  bottom: 0;
  right: 0;
}

.rw-popup-container {
  position: absolute;
  z-index: 1005;
  top: 100%;
  left: -6px;
  right: -6px;
}
.rw-popup-container.rw-dropup {
  top: auto;
  bottom: 100%;
}
.rw-state-focus .rw-popup-container {
  z-index: 1006;
}

.rw-popup-transition {
  width: 100%;
  margin-bottom: 6px;
  padding: 0 6px;
}
.rw-dropup > .rw-popup-transition {
  margin-bottom: 0;
  margin-top: 6px;
}

.rw-popup {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  border: #e5eced 1px solid;
  background: #fff;
}
.rw-dropup .rw-popup {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
}

.rw-popup-transition {
  transition: transform 200ms;
}

.rw-popup-transition-entering {
  overflow: hidden;
}
.rw-popup-transition-entering .rw-popup-transition {
  transform: translateY(0);
  transition-timing-function: ease-out;
}

.rw-popup-transition-exiting .rw-popup-transition {
  transition-timing-function: ease-in;
}

.rw-popup-transition-exiting,
.rw-popup-transition-exited {
  overflow: hidden;
}
.rw-popup-transition-exiting .rw-popup-transition,
.rw-popup-transition-exited .rw-popup-transition {
  transform: translateY(-100%);
}
.rw-popup-transition-exiting.rw-dropup .rw-popup-transition,
.rw-popup-transition-exited.rw-dropup .rw-popup-transition {
  transform: translateY(100%);
}

.rw-popup-transition-exited {
  display: none;
}

.rw-state-disabled {
  box-shadow: none;
  cursor: not-allowed;
}

> label {
  font-weight: normal;
  font-size: 0.875rem;
  user-select: none;
}

.rw-widget {
  font-size: 1.125rem;
}
.rw-widget.success .rw-btn {
  color: #339999;
}
.rw-widget.success .rw-input, .rw-widget.success .rw-dropdown-list-autofill, .rw-widget.success .rw-filter-input {
  color: #000000;
  font-weight: bold;
  padding: 0;
}
.rw-widget.success .rw-widget-container {
  border-color: transparent;
  height: 14px;
  background-color: transparent;
}
.rw-widget.success .rw-widget-input, .rw-widget.success .rw-filter-input {
  box-shadow: 0 11px 14px -10px #339999;
}
.rw-widget.success .rw-widget-container {
  border-left: transparent;
  background-color: transparent;
  box-shadow: none;
}
.rw-widget.success .rw-list-optgroup {
  border-left: none;
  background-color: transparent;
}
.rw-widget.success .rw-list-option {
  border: none;
  background-color: #d2e3e3;
  border-left: none;
}
.rw-widget.success .rw-list-option:not(:last-of-type) {
  border-bottom: 1px solid #e5eced !important;
}
.rw-widget.success .rw-list-option.rw-state-selected {
  color: #339999;
}
.rw-widget.success .rw-list-option:hover, .rw-widget.success .rw-list-option:hover.rw-state-focus {
  background-color: #e6e6e6 !important;
  border-color: #339999;
  color: #4a4a4a;
}
.rw-widget.error .rw-btn {
  color: #cb6566;
}
.rw-widget.error .rw-input, .rw-widget.error .rw-dropdown-list-autofill, .rw-widget.error .rw-filter-input {
  color: #000000;
  font-weight: bold;
  padding: 0;
}
.rw-widget.error .rw-widget-container {
  border-color: transparent;
  height: 14px;
  background-color: transparent;
}
.rw-widget.error .rw-widget-input, .rw-widget.error .rw-filter-input {
  box-shadow: 0 11px 14px -10px #cb6566;
}
.rw-widget.error .rw-widget-container {
  border-left: transparent;
  background-color: transparent;
  box-shadow: none;
}
.rw-widget.error .rw-list-optgroup {
  border-left: none;
  background-color: transparent;
}
.rw-widget.error .rw-list-option {
  border: none;
  background-color: #d2e3e3;
  border-left: none;
}
.rw-widget.error .rw-list-option:not(:last-of-type) {
  border-bottom: 1px solid #e5eced !important;
}
.rw-widget.error .rw-list-option.rw-state-selected {
  color: #cb6566;
}
.rw-widget.error .rw-list-option:hover, .rw-widget.error .rw-list-option:hover.rw-state-focus {
  background-color: #e6e6e6 !important;
  border-color: #cb6566;
  color: #4a4a4a;
}

.rw-widget .rw-select .rw-i-caret-down::before {
  content: "";
  border: solid #000000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
}

.rw-dropdown-list-input {
  font-weight: normal;
}

.rw-widget-container {
  border-left: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.rw-list {
  max-height: unset;
}

.rw-list-optgroup {
  border-left: 3px solid #e5eced;
  color: #000000;
  padding-bottom: 7px;
  padding-top: 15px;
  text-transform: uppercase;
}

.rw-list-option {
  border: none;
  border-left: 3px solid #e5eced;
}
.rw-list-option:not(:last-of-type) {
  border-bottom: 1px solid #e5eced !important;
}
.rw-list-option:hover, .rw-list-option:hover.rw-state-focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4a4a4a;
}

.rw-popup-container {
  top: 0;
  border: none;
  border-radius: 0;
  position: relative;
  left: 0;
  right: 0;
}
.rw-popup-container .rw-popup-transition {
  padding-left: 0;
  padding-right: 0;
}

.rw-popup {
  border: none;
  border-radius: 0;
  background-color: #d2e3e3;
}

.rw-select > * {
  height: 0;
}

.rw-placeholder {
  color: #000000;
  line-height: 1.2rem;
}

.rw-input,
.rw-filter-input,
.rw-dropdown-list-autofill {
  color: #4a4a4a;
  padding: 0;
  background-color: transparent;
}

.rw-widget-container.rw-state-focus,
.rw-widget-container.rw-state-focus:hover,
.rw-state-focus > .rw-widget-container,
.rw-state-focus > .rw-widget-container:hover {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}