.container {
  background: #ffffff;
}
@media only screen and (max-width: 991px) {
  .container.widgetMode {
    padding-top: 60px;
  }
}
.container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 60px);
  background-repeat: no-repeat;
  background-position-y: 20%;
  background-position-x: 100%;
}
@media (max-width: 767px) {
  .container .content {
    background-position-x: 20%;
  }
}
.container.loading {
  background: #ffffff;
}
.container.loading .content {
  visibility: hidden;
}

:global html {
  background: #f5fafa;
}