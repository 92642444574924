.host {
  position: relative;
  padding: 0 20px;
  margin-right: -10px;
  z-index: 30;
}

.button {
  display: flex;
  align-items: center;
  background: transparent;
  color: var(--color-black);
  column-gap: 6px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}

.triggerButton {
  background: transparent;
  color: var(--color-white);
  column-gap: 4px;
}

.overlayContainer {
  position: relative;
}

.overlay {
  position: absolute;
  margin-top: 5px;
  padding: 0 8px;
  right: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  background: rgb(255, 255, 255);
  display: block;
  z-index: 999999;
  bottom: 0;
}

.overlayContainer {
  transition: height 0.5s ease-in-out;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  transform: translateY(4px);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
}

.overlay-container-exiting,
.overlay-container-exited {
  height: 0;
}

.backdrop {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(255, 255, 255, 0);
  z-index: 20;
}

.overlay-entering,
.overlay-entered {
  height: auto;
  bottom: 0;
}

.overlayItem {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.lngName {
  font-size: 16px;
}