.header {
  background-color: rgba(30, 33, 33, 0.81);
  z-index: 2000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .container {
    width: 500px;
    align-self: unset;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .headerContainer {
    padding-right: 20px;
  }
}

.brand {
  display: inline-block;
}

.headerLogos {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media only screen and (max-width: 767px) {
  .headerLogos {
    gap: 14px;
  }
}

.headerLogos:nth-child(2) {
  gap: 4px;
}
@media only screen and (max-width: 767px) {
  .headerLogos:nth-child(2) {
    gap: 0px;
  }
}

.logo {
  max-height: 40px;
  width: 117px;
}
@media only screen and (max-width: 767px) {
  .logo {
    padding-left: 20px;
  }
}