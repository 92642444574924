.userInfoContainer {
  position: relative;
}

.personBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}

.userModal {
  position: absolute;
  z-index: 10;
  visibility: hidden;
  width: 300px;
  height: 250px;
  top: 90%;
  padding: 10px 0;
  right: -130%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.15s, visibility 0.3s ease-in-out 0.15s, transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) 0.45s;
}
@media only screen and (max-width: 767px) {
  .userModal {
    width: 250px;
    right: -30%;
  }
}

.userModalBlock {
  visibility: hidden;
  background-color: white;
  height: 100%;
  box-shadow: 0 11px 14px -10px gray;
  border-radius: 10px;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.15s, visibility 0.3s ease-in-out 0.15s, transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) 0.45s;
}

.userModalBlock.isVisible,
.userModal.isVisible {
  visibility: visible;
  opacity: 1;
  transform: translateX(0) translateY(0);
}

.logOutBtn {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.userInfo p {
  margin: 0 4px;
  width: 100%;
  word-wrap: break-word;
  text-align: center;
}
.userInfo svg {
  margin-bottom: 10px;
}

.logOutBtnContainer {
  margin-top: 50px;
  border-top: solid rgba(74, 74, 74, 0.2588235294);
  width: 100%;
  padding-top: 10px;
}

.isVisiblePersonBtn {
  display: block;
}