.button {
  background: #e5eced;
  color: #ffffff;
  border-radius: 2em;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
  outline: none;
  cursor: pointer;
  user-select: none;
}
.button:hover:not(:disabled), .button:focus:not(:disabled) {
  text-decoration: none;
}
.button:hover:not(:disabled).textButton, .button:focus:not(:disabled).textButton {
  color: #ffffff;
}
.button:disabled {
  cursor: not-allowed;
}
.button.textButton {
  background: transparent;
  color: #e5eced;
}
.button.primary {
  background: #339999;
}
.button.primary:hover:not(:disabled), .button.primary:focus:not(:disabled) {
  background: #2d8686;
}
.button.primary:disabled {
  background: #2d8686;
  opacity: 0.5;
}
.button.primary.textButton {
  background: transparent;
  color: #339999;
}
.button.primary.textButton:hover, .button.primary.textButton:focus {
  color: #ffffff;
  background: #339999;
}
.button.primary.iconButton {
  background: transparent;
  color: #339999;
}
.button.primary.iconButton:hover:not(:disabled), .button.primary.iconButton:focus:not(:disabled) {
  background: #98cb98;
}
.button.secondary {
  background: #9b9b9b;
}
.button.secondary:hover:not(:disabled), .button.secondary:focus:not(:disabled) {
  background: #8e8e8e;
}
.button.secondary.textButton {
  background: transparent;
  color: #9b9b9b;
}
.button.secondary.textButton:hover:not(:disabled), .button.secondary.textButton:focus:not(:disabled) {
  color: #ffffff;
  background: #9b9b9b;
}
.button.secondary.iconButton {
  background: transparent;
  color: #4a4a4a;
}
.button.secondary.iconButton:hover:not(:disabled), .button.secondary.iconButton:focus:not(:disabled) {
  background: #f6f8f9;
}
.button.blue {
  background: #4a90e2;
}
.button.blue:hover:not(:disabled), .button.blue:focus:not(:disabled) {
  background: #3483de;
}
.button.blue.textButton {
  background: transparent;
  color: #4a90e2;
}
.button.blue.textButton:hover:not(:disabled), .button.blue.textButton:focus:not(:disabled) {
  color: #ffffff;
  background: #4a90e2;
}
.button.blue.iconButton {
  background: transparent;
  color: #4a90e2;
}
.button.blue.iconButton:hover:not(:disabled), .button.blue.iconButton:focus:not(:disabled) {
  background: #f6f8f9;
}
.button.whiteBlue {
  background: #ffffff;
  outline: 1px solid #339999;
  outline-offset: -2px;
  color: #339999;
}
.button.whiteBlue:hover:not(:disabled), .button.whiteBlue:focus:not(:disabled) {
  background: rgba(50, 151, 151, 0.07);
}
.button.white {
  background: #ffffff;
  color: #339999;
}
.button.white:hover:not(:disabled), .button.white:focus:not(:disabled) {
  background: #f2f2f2;
}
.button.white.textButton {
  background: transparent;
  color: #4a90e2;
}
.button.white.textButton:hover:not(:disabled), .button.white.textButton:focus:not(:disabled) {
  color: #ffffff;
  background: #4a90e2;
}
.button.white.iconButton {
  background: transparent;
  color: #4a90e2;
}
.button.white.iconButton:hover:not(:disabled), .button.white.iconButton:focus:not(:disabled) {
  background: #f6f8f9;
}
.button.transparent {
  background: transparent;
  color: #4a90e2;
  border: solid #4a90e2;
}
.button.transparent:hover:not(:disabled), .button.transparent:focus:not(:disabled) {
  background: #f2f2f2;
}
.button.transparent.textButton {
  background: transparent;
  color: #4a90e2;
}
.button.transparent.textButton:hover:not(:disabled), .button.transparent.textButton:focus:not(:disabled) {
  color: #ffffff;
  background: #4a90e2;
}
.button.transparent.iconButton {
  background: transparent;
  color: #4a90e2;
}
.button.transparent.iconButton:hover:not(:disabled), .button.transparent.iconButton:focus:not(:disabled) {
  background: #f6f8f9;
}

.loader {
  display: block;
  background: url(data:image/gif;base64,R0lGODlhEAAQAPIAAP///zMzM87OzmdnZzMzM4GBgZqamqenpyH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==) no-repeat 50%;
  min-width: 16px;
  width: 1.9em;
  height: 1em;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}