.container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #d2e3e3;
  padding: 20px 10px;
}
.container .calendarDayCell.selectable.selectable:hover .circle, .container .calendarDayCell.selectable.selectable:focus .circle {
  background: #9b9b9b;
}
.container .calendarDayCell.selectable.selectable:hover .dayN, .container .calendarDayCell.selectable.selectable:focus .dayN {
  color: #ffffff;
}
.container .calendarDayCell.selectable.selectable.selected .circle {
  background: #ffffff;
  box-shadow: 0 5px 5px -3px #99cccc;
}
.container .calendarDayCell.selectable.selectable.selected .dayN {
  color: #ffffff;
}
.container .calendarDayCell.selectable .circle {
  font-size: 1.125rem;
  cursor: pointer;
}
.container .calendarDayCell.selectable .dayN {
  color: #4a4a4a;
  opacity: 1;
  font-size: 0.95rem;
}
.container.success {
  border-color: #339999;
  box-shadow: none;
}
.container.success .calendarDayCell.selectable.selectable:hover .circle, .container.success .calendarDayCell.selectable.selectable:focus .circle {
  background: #9b9b9b;
}
.container.success .calendarDayCell.selectable.selectable:hover .dayN, .container.success .calendarDayCell.selectable.selectable:focus .dayN {
  color: #ffffff;
}
.container.success .calendarDayCell.selectable.selectable.selected .circle {
  background: #339999;
  box-shadow: 0 5px 5px -3px #99cccc;
}
.container.success .calendarDayCell.selectable.selectable.selected .dayN {
  color: #ffffff;
}
.container.success .calendarDayCell.selectable .circle {
  font-size: 1.125rem;
  cursor: pointer;
}
.container.success .calendarDayCell.selectable .dayN {
  color: #4a4a4a;
  opacity: 1;
  font-size: 0.95rem;
}
.container.success .monthTitle {
  color: #4a4a4a;
  font-size: 1.1rem;
}
.container.error {
  border-color: #cb6566;
  box-shadow: none;
}
.container.error .calendarDayCell.selectable.selectable:hover .circle, .container.error .calendarDayCell.selectable.selectable:focus .circle {
  background: #9b9b9b;
}
.container.error .calendarDayCell.selectable.selectable:hover .dayN, .container.error .calendarDayCell.selectable.selectable:focus .dayN {
  color: #ffffff;
}
.container.error .calendarDayCell.selectable.selectable.selected .circle {
  background: #cb6566;
  box-shadow: 0 5px 5px -3px #99cccc;
}
.container.error .calendarDayCell.selectable.selectable.selected .dayN {
  color: #ffffff;
}
.container.error .calendarDayCell.selectable .circle {
  font-size: 1.125rem;
  cursor: pointer;
}
.container.error .calendarDayCell.selectable .dayN {
  color: #4a4a4a;
  opacity: 1;
  font-size: 0.95rem;
}

.calendarDayCell {
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-basis: 14.285%;
}
.calendarDayCell.invisible {
  visibility: hidden;
}
.calendarDayCell.selectable .circle {
  background: #ffffff;
  opacity: 1;
}

.circle {
  font-size: 0.875rem;
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 50%;
  font-weight: normal;
  text-align: center;
  margin-right: 10px;
  user-select: none;
  cursor: not-allowed;
  background: #f6f8f9;
  opacity: 0.5;
}
.circle:last-of-type {
  margin-right: 0;
}

.disabled {
  user-select: none;
  pointer-events: none;
}
.disabled .dayN {
  color: #ffffff;
  opacity: 1;
}
.disabled .circle {
  background-color: #ccc;
  border: 2px solid #cb6566;
  user-select: none;
  cursor: not-allowed;
  position: relative;
}
.disabled .circle::before {
  content: "";
  display: block;
  height: 2px;
  width: 2em;
  background-color: #cb6566;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -1em;
  transform: rotate(45deg);
}

.dayN {
  font-size: 0.875rem;
  color: #4a4a4a;
}

.daysRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #99cccc;
  border-radius: 10px;
  margin: 0 14px 18px;
  padding: 3px;
}

.leftControlsContainer,
.rightControlsContainer {
  flex-basis: 14.285%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.leftControlsContainer .arrowContainer {
  text-align: left;
}

.rightControlsContainer .arrowContainer {
  text-align: right;
}

.arrowContainer {
  flex-basis: 2.25rem;
}
.arrowContainer .iconButton {
  color: #4a4a4a;
}

.monthTitle {
  flex-basis: 20%;
  font-weight: bold;
  font-size: 1.225rem;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  text-transform: capitalize;
  color: #4a4a4a;
}

.dayName {
  font-weight: bold;
  font-size: 1rem;
  user-select: none;
  flex-basis: 14.285%;
  text-align: center;
}

.weekContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: flex-end;
}

.arrow {
  font-size: 1.125rem;
  margin-top: 0;
}
.arrow svg {
  width: 0.875rem;
  height: 0.875rem;
}

.weeksContainer {
  display: flex;
  flex-direction: column;
}