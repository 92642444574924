.li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.textBlock {
  width: 76%;
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: bold;
  font-size: 1.125rem;
}

.description {
  font-weight: lighter;
  font-size: 0.875rem;
}

.loadMoreButton {
  margin: 0 auto !important;
}

.button {
  width: 22%;
  height: 35px;
  border-radius: 17.5px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.875rem;
  font-weight: lighter;
  background: #ff9966;
}
.button.single {
  color: #4a4a4a;
  background: #f6eb65;
}
.button.packet {
  color: #4a4a4a;
  background: #cbc9e6;
}
.button.courses {
  color: #4a4a4a;
  background: #facb9a;
}
.button.other {
  color: #4a4a4a;
  background: #f8cacb;
}

.selected .button {
  background: #339999;
}

.perPerson {
  font-size: 0.6125rem;
}