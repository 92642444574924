.legendItem {
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.125rem;
}
.legendItem svg {
  width: 1em;
  height: 1em;
}

.circle {
  font-size: 1.125rem;
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: normal;
  margin-right: 10px;
  user-select: none;
  color: #ffffff;
  flex-shrink: 0;
}
@media (max-width: 380px) {
  .circle {
    height: 1.4625rem;
    width: 1.4625rem;
    font-size: 14px;
  }
}
.circle.darkSkyBlue {
  background: #4a90e2;
}
.circle.fadedOrange {
  background: #f29668;
}
.circle.paleOrange {
  background: #ff9966;
}
.circle.greyishBrown {
  background: #686868;
}
.circle.greyishBrownTwo {
  background: #4a4a4a;
}
.circle.lichen {
  background: #98cb98;
}
.circle.lightBlueGrey {
  background: #cac8e5;
}
.circle.lightGreyBlue {
  background: #99cccc;
}
.circle.lightGreyGreen {
  background: #cfe4c3;
}
.circle.mushroom {
  background: #cb9898;
}
.circle.paleGrey {
  background: #e5eced;
}
.circle.paleGreyTwo {
  background: #f6f8f9;
}
.circle.paleRose {
  background: #f8cacb;
}
.circle.rose {
  background: #cb6566;
}
.circle.rosePink {
  background: #f19698;
}
.circle.trailerPink {
  background: #ff69b4;
}
.circle.sea {
  background: #339999;
}
.circle.sickGreen {
  background: #cccb32;
}
.circle.warmGrey {
  background: #9b9b9b;
}
.circle.wheat {
  background: #facb9a;
}
.circle.white {
  background: #ffffff;
}
.circle.wisteria {
  background: #b399c8;
}
.circle.yellowish {
  background: #f6eb65;
}
.circle.pastelBlue {
  background: #afdafc;
}
.circle.brightOrange {
  background: #ffa500;
}
.circle.magenta {
  background: #b726c1;
}
.circle.darkViolet {
  background: #701576;
}
.circle.darkPink {
  background: #aa336a;
}
.circle.info {
  background: #addeee;
}
.circle.black {
  background: #000000;
}

.attention {
  border: 2px solid #b00000;
  box-sizing: border-box;
  position: relative;
}
.attention .attention-after {
  position: absolute;
  font-size: 9px;
  line-height: 13.5px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: #b00000;
  width: 1.125rem;
  height: 1.125rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.125rem;
  top: -0.281rem;
  left: -0.562rem;
}

.listRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.legendItemContainer {
  flex-basis: 50%;
}

.legendItemDescription {
  font-size: 0.875rem;
}