.card {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  padding: 40px 10px 40px;
}
.card.primary {
  border-color: #339999;
}
.card.secondary {
  border-color: #d3e6e6;
}