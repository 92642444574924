.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.title {
  line-height: 1.5;
  font-weight: 500;
}

.loader {
  text-align: center;
  max-width: 100%;
  min-width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}