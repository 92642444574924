.calendar {
  border: none;
  padding: 0;
  background: transparent;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 14px 14px -14px;
}
.linksContainer a {
  cursor: pointer;
  padding: 6px 8px;
  text-decoration: none;
  border: solid 0.5px;
  border-radius: 10px;
  color: #339999;
}

.invisible {
  visibility: hidden;
}

.calendarDayCell {
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: normal;
  flex-basis: 14.285%;
  cursor: not-allowed;
}
.calendarDayCell.invisible {
  visibility: hidden;
}
.calendarDayCell.selectable {
  cursor: pointer;
  font-size: 1.125rem;
}
.calendarDayCell.selected .circle {
  border: 5px solid black;
}
.calendarDayCell.darkSkyBlue:hover .circle {
  background: #3483de;
}
.calendarDayCell.darkSkyBlue .circle {
  background: #4a90e2;
}
.calendarDayCell.darkSkyBlue .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.fadedOrange:hover .circle {
  background: #f08651;
}
.calendarDayCell.fadedOrange .circle {
  background: #f29668;
}
.calendarDayCell.fadedOrange .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.paleOrange:hover .circle {
  background: #ff884d;
}
.calendarDayCell.paleOrange .circle {
  background: #ff9966;
}
.calendarDayCell.paleOrange .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.greyishBrown:hover .circle {
  background: #5b5b5b;
}
.calendarDayCell.greyishBrown .circle {
  background: #686868;
}
.calendarDayCell.greyishBrown .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.greyishBrownTwo:hover .circle {
  background: #3d3d3d;
}
.calendarDayCell.greyishBrownTwo .circle {
  background: #4a4a4a;
}
.calendarDayCell.greyishBrownTwo .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.lichen:hover .circle {
  background: #87c287;
}
.calendarDayCell.lichen .circle {
  background: #98cb98;
}
.calendarDayCell.lichen .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.lightBlueGrey:hover .circle {
  background: #b9b7dd;
}
.calendarDayCell.lightBlueGrey .circle {
  background: #cac8e5;
}
.calendarDayCell.lightBlueGrey .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.lightGreyBlue:hover .circle {
  background: #88c4c4;
}
.calendarDayCell.lightGreyBlue .circle {
  background: #99cccc;
}
.calendarDayCell.lightGreyBlue .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.lightGreyGreen:hover .circle {
  background: #c1dcb1;
}
.calendarDayCell.lightGreyGreen .circle {
  background: #cfe4c3;
}
.calendarDayCell.lightGreyGreen .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.mushroom:hover .circle {
  background: #c28787;
}
.calendarDayCell.mushroom .circle {
  background: #cb9898;
}
.calendarDayCell.mushroom .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.paleGrey:hover .circle {
  background: #d6e1e3;
}
.calendarDayCell.paleGrey .circle {
  background: #e5eced;
}
.calendarDayCell.paleGrey .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.paleGreyTwo:hover .circle {
  background: #e7ecef;
}
.calendarDayCell.paleGreyTwo .circle {
  background: #f6f8f9;
}
.calendarDayCell.paleGreyTwo .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.paleRose:hover .circle {
  background: #f5b3b5;
}
.calendarDayCell.paleRose .circle {
  background: #f8cacb;
}
.calendarDayCell.paleRose .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.rose:hover .circle {
  background: #c55253;
}
.calendarDayCell.rose .circle {
  background: #cb6566;
}
.calendarDayCell.rose .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.rosePink:hover .circle {
  background: #ee8082;
}
.calendarDayCell.rosePink .circle {
  background: #f19698;
}
.calendarDayCell.rosePink .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.trailerPink:hover .circle {
  background: #ff50a7;
}
.calendarDayCell.trailerPink .circle {
  background: #ff69b4;
}
.calendarDayCell.trailerPink .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.sea:hover .circle {
  background: #2d8686;
}
.calendarDayCell.sea .circle {
  background: #339999;
}
.calendarDayCell.sea .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.sickGreen:hover .circle {
  background: #b8b72d;
}
.calendarDayCell.sickGreen .circle {
  background: #cccb32;
}
.calendarDayCell.sickGreen .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.warmGrey:hover .circle {
  background: #8e8e8e;
}
.calendarDayCell.warmGrey .circle {
  background: #9b9b9b;
}
.calendarDayCell.warmGrey .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.wheat:hover .circle {
  background: #f9be82;
}
.calendarDayCell.wheat .circle {
  background: #facb9a;
}
.calendarDayCell.wheat .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.white:hover .circle {
  background: #f2f2f2;
}
.calendarDayCell.white .circle {
  background: #ffffff;
}
.calendarDayCell.white .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.wisteria:hover .circle {
  background: #a788bf;
}
.calendarDayCell.wisteria .circle {
  background: #b399c8;
}
.calendarDayCell.wisteria .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.yellowish:hover .circle {
  background: #f5e84d;
}
.calendarDayCell.yellowish .circle {
  background: #f6eb65;
}
.calendarDayCell.yellowish .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.pastelBlue:hover .circle {
  background: #96cffb;
}
.calendarDayCell.pastelBlue .circle {
  background: #afdafc;
}
.calendarDayCell.pastelBlue .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.brightOrange:hover .circle {
  background: #e69500;
}
.calendarDayCell.brightOrange .circle {
  background: #ffa500;
}
.calendarDayCell.brightOrange .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.magenta:hover .circle {
  background: #a322ac;
}
.calendarDayCell.magenta .circle {
  background: #b726c1;
}
.calendarDayCell.magenta .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.darkViolet:hover .circle {
  background: #5b1160;
}
.calendarDayCell.darkViolet .circle {
  background: #701576;
}
.calendarDayCell.darkViolet .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.darkPink:hover .circle {
  background: #962d5e;
}
.calendarDayCell.darkPink .circle {
  background: #aa336a;
}
.calendarDayCell.darkPink .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.info:hover .circle {
  background: #98d6ea;
}
.calendarDayCell.info .circle {
  background: #addeee;
}
.calendarDayCell.info .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.black:hover .circle {
  background: black;
}
.calendarDayCell.black .circle {
  background: #000000;
}
.calendarDayCell.black .circle .dayN {
  color: #ffffff;
  opacity: 1;
}
.calendarDayCell.attention .circle {
  border: 2px solid #b00000;
  box-sizing: border-box;
  position: relative;
  line-height: calc(2em - 4px);
}
.calendarDayCell.attention .attention-after {
  position: absolute;
  font-size: 9px;
  line-height: 13.5px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: #b00000;
  width: 1.125rem;
  height: 1.125rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.125rem;
  top: -0.281rem;
  left: -0.562rem;
}

.circle {
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  user-select: none;
  background: rgba(255, 255, 255, 0.6);
  position: relative;
}
.circle:last-of-type {
  margin-right: 0;
}

.dayN {
  opacity: 1;
  font-size: 1rem;
  color: #4a4a4a;
}

.subcount,
.message {
  position: absolute;
  color: #ffffff;
  background: black;
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.125rem;
  top: -0.281rem;
  right: -0.562rem;
}

.message {
  top: 1.2rem;
  color: #ffffff;
  background-color: #4a90e2;
  font-size: 0.85rem;
}

.title {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 24px;
}

.collapseButton {
  margin-left: 10px;
  width: 0.875rem;
  height: 0.875rem;
  color: #9b9b9b;
  transform: rotate(-90deg);
  cursor: pointer;
}
.collapseButton.collapsed {
  transform: rotate(90deg);
}

.collapseBtn {
  color: #339999;
  background-color: transparent;
  text-align: center;
  border-radius: 2rem;
  margin: -14px 14px 4px;
  margin-left: auto;
}
.collapseBtn:focus {
  outline: none;
}
.collapseBtn.collapsed span {
  box-shadow: 0 0 0 1px rgba(74, 144, 226, 0);
}

.collapseLabel {
  box-shadow: 0 0 0 1px #339999;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #339999;
  line-height: 12px;
  border-radius: 2rem;
  display: inline-block;
  transition: box-shadow 0.3s;
  padding: 6px 8px;
}
@media (max-width: 400px) {
  .collapseLabel {
    display: none;
    line-height: 20px;
  }
}
.collapseLabelHidden {
  padding: 0;
  width: 20px;
  display: none;
}
@media (max-width: 400px) {
  .collapseLabelHidden {
    display: block;
  }
}

.legend {
  overflow: hidden;
  transition-property: all;
  transition-duration: 1s;
  max-height: 330px;
}
.legend.isCollapsed {
  max-height: 0;
}
@media (max-width: 380px) {
  .legend {
    max-height: 220px;
  }
}